import Icon from 'components/Icon'
import InvisibleButton from 'components/InvisibleButton'
import Link from 'components/Link'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { useToggle } from 'hooks/useToggle'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { authStateSelector } from 'store/auth/selector'
import theme from 'styles/theme'

const MobileNav = ({
  header: { mainLinks, dropdownTitleLink, dropdownLinks, userAccountLink },
}) => {
  const {
    isToggled: productsOpen,
    toggle: toggleProductsOpen,
    setToggle: setProductsOpen,
  } = useToggle(false)
  const dropdownRef = useRef(null)
  const hamburgerMenuRef = useRef(null)
  const authState = useSelector(authStateSelector)
  const [selectedMenu, setSelectedMenu] = useState(dropdownTitleLink.text)
  const [selectedSubMenu, setSelectedSubMenu] = useState()
  useOnClickOutside([dropdownRef, hamburgerMenuRef], () => setProductsOpen(false), productsOpen)

  const onArrowClicked = linkText => {
    if (selectedMenu === linkText) setSelectedMenu(false)
    else setSelectedMenu(linkText)
  }

  const onSubCategoryArrowClicked = linkGroup => {
    setSelectedSubMenu(linkGroup)
  }

  return (
    <>
      <InvisibleButton
        ref={hamburgerMenuRef}
        onClick={toggleProductsOpen}
        css={{
          padding: '1rem 1.5rem',
          [theme.headerBreakpoint.large]: {
            display: 'none',
          },
        }}
      >
        <Icon.Menu
          width={24}
          height={24}
          css={{ color: theme.colors.spinachDark }}
          aria-hidden='true'
        />
        <span className='sr-only'>Menu</span>
      </InvisibleButton>

      {productsOpen && (
        <nav
          ref={dropdownRef}
          className='MobileNav'
          css={{
            [theme.headerBreakpoint.large]: {
              display: 'none',
            },
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            zIndex: theme.zIndex.navigation,
            backgroundColor: theme.colors.miscBackground,
            boxShadow: theme.boxShadow.deep,
            'a, button': {
              fontSize: '1.25rem',
            },
          }}
        >
          <Link
            to={authState.currentUser ? '/my-account' : userAccountLink}
            target='_self'
            css={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: theme.colors.spinachDark,
              color: theme.colors.creamless,
              textDecoration: 'none',
              padding: '2rem 1.25rem',
              ':hover': {
                backgroundColor: theme.colors.cabbage,
              },
            }}
          >
            <Icon.PersonOutline
              width={24}
              height={24}
              css={{ color: theme.colors.creamless }}
              aria-hidden='true'
            />
            <span
              css={{
                marginLeft: '1rem',
              }}
            >
              {authState.currentUser ? 'My Account' : 'Login'}
            </span>
          </Link>
          <ul
            css={{
              margin: '0',
              display: selectedSubMenu && 'none',
              padding: '2rem 0 3rem',
              listStyle: 'none',
              '.link': {
                padding: '1rem 1.25rem',
                display: 'flex',
                justifyContent: 'space-between',
              },
              'a, button': {
                display: 'block',
                textDecoration: 'none',
                backgroundColor: 'transparent',
                padding: 0,
                border: 'none',
                outline: 'none',
                color: 'inherit',
                ':hover': {
                  color: theme.colors.spinachDark,
                },
              },
            }}
          >
            <li>
              <div className='link'>
                <Link
                  to={dropdownTitleLink.url}
                  className={dropdownTitleLink.linkClass}
                  onClick={() => {
                    setProductsOpen(false)
                  }}
                >
                  {dropdownTitleLink.text}
                </Link>
                {dropdownLinks.length > 0 && (
                  <Icon.DropdownArrow
                    width={24}
                    css={{
                      color: 'inherit',
                      transform: selectedMenu !== dropdownTitleLink.text && 'rotate(-90deg)',
                      transition: 'all 0.3s',
                      width: '24px',
                      height: '24px',
                    }}
                    onClick={() => onArrowClicked(dropdownTitleLink.text)}
                    aria-hidden='true'
                  />
                )}
              </div>
              {selectedMenu === dropdownTitleLink.text && (
                <ul
                  css={{
                    listStyle: 'none',
                  }}
                >
                  {dropdownLinks.map((link, i) => (
                    <li key={i}>
                      <div className='link'>
                        <Link
                          to={link.url}
                          onClick={() => {
                            setProductsOpen(false)
                          }}
                          css={{
                            color: theme.colors.kale,
                          }}
                        >
                          {link.title}
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </li>
            {mainLinks
              .filter(link => link.navPosition === 'Right')
              .map((link, i) => (
                <li key={i}>
                  <div className='link'>
                    <Link
                      to={link.link.url}
                      onClick={() => {
                        setProductsOpen(false)
                      }}
                      className={link.link.linkClass}
                    >
                      {link.link.text}
                    </Link>
                    {link.navigationGroup.length > 0 && (
                      <Icon.DropdownArrow
                        width={24}
                        css={{
                          color: 'inherit',
                          transform: selectedMenu !== link.link.text && 'rotate(-90deg)',
                          transition: 'all 0.3s',
                          width: '24px',
                          height: '24px',
                        }}
                        onClick={() => onArrowClicked(link.link.text)}
                        aria-hidden='true'
                      />
                    )}
                  </div>

                  {selectedMenu === link.link.text && (
                    <ul
                      css={{
                        listStyle: 'none',
                        marginBottom: '1rem',
                      }}
                    >
                      {link.navigationGroup.map((group, idx) => (
                        <li>
                          <div key={idx} className='link'>
                            <Link
                              to={group.links[0].url}
                              css={{
                                color: theme.colors.kale,
                              }}
                              onClick={() => {
                                setProductsOpen(false)
                              }}
                            >
                              {group.links[0].text}
                            </Link>
                            {group.links.length > 1 && (
                              <Icon.DropdownArrow
                                width={24}
                                css={{
                                  color: 'inherit',
                                  transform: 'rotate(-90deg)',
                                  transition: 'all 0.3s',
                                  width: '24px',
                                  height: '24px',
                                }}
                                onClick={() => onSubCategoryArrowClicked(group)}
                              />
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
          <div
            css={{
              display: !selectedSubMenu ? 'none' : 'flex',
              alignItems: 'flex-start',
              padding: '2rem 1.25rem 3rem',
            }}
          >
            <Icon.DropdownArrow
              width={24}
              css={{
                color: 'inherit',
                paddingTop: '1.5rem',
                transform: 'rotate(90deg)',
                transition: 'all 0.3s',
              }}
              onClick={() => onSubCategoryArrowClicked(false)}
            />
            <ul
              css={{
                margin: '0',
                padding: '0',
                listStyle: 'none',
                'a, button': {
                  display: 'block',
                  textDecoration: 'none',
                  backgroundColor: 'transparent',
                  padding: '1rem 0',
                  border: 'none',
                  outline: 'none',
                  color: 'inherit',
                  ':hover': {
                    color: theme.colors.spinachDark,
                  },
                },
              }}
            >
              {selectedSubMenu &&
                selectedSubMenu.links.map(link => (
                  <li
                    css={{
                      ':first-child': {
                        'a, button': {
                          fontWeight: theme.fontWeight.bold,
                        },
                      },
                    }}
                  >
                    <Link
                      to={link.url}
                      css={{
                        color: theme.colors.kale,
                      }}
                      onClick={() => {
                        setProductsOpen(false)
                      }}
                    >
                      {link.text}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </nav>
      )}
    </>
  )
}

const linkType = PropTypes.shape({
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  linkClass: PropTypes.string,
})

const headerNavType = PropTypes.shape({
  link: linkType,
  navPosition: PropTypes.string.isRequired,
  ctaButtonText: PropTypes.string,
  navigationGroup: PropTypes.arrayOf(
    PropTypes.shape({
      links: PropTypes.arrayOf(linkType),
    })
  ),
})

const productLinkType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.object,
})

const headerType = PropTypes.shape({
  mainLinks: PropTypes.arrayOf(headerNavType).isRequired,
  dropdownTitleLink: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    linkClass: PropTypes.string,
  }),
  dropdownLinks: PropTypes.arrayOf(productLinkType).isRequired,
  userAccountLink: PropTypes.string.isRequired,
})

MobileNav.propTypes = { header: headerType }

export default MobileNav
